export const SearchModelBackwardLookup: any = {
    1: {
        100: {
            itemName: "event_name",
            formal_name: "event_title",
            searchMethod: "event_name_search_method",
        },
        145: {
            itemName: "keyword",
        },
        107: {
            itemName: "event_name",
            itemId: "event_id",
        },
        108: {
            itemName: "event_search_name",
            itemId: "event_search_id",
        },
        192: {
            itemName: "event_exclude_search_name",
            itemId: "event_exclude_search_id",
        },
        104: {
            itemName: "event_folder_name",
            itemId: "event_folder_id",
        },
        105: {
            itemName: "event_cabinet_name",
            itemId: "event_cabinet_id",
        },
        103: {
            itemName: "event_type_name",
            itemId: "event_type_id",
        },
        102: {
            itemName: "event_type_name",
            itemId: "event_type_id",
        },
        101: {
            itemName: "event_state_name",
            itemId: "event_state_id",
        },
        110: {
            itemName: "organization_name",
            itemId: "organization_id",
        },
        112: {
            itemName: "organization_name",
            itemId: "organization_id",
        },
        113: {
            itemName: "organization_search_name",
            itemId: "organization_search_id",
        },
        111: {
            itemName: "contact_name",
            itemId: "contact_id",
        },
        120: {
            itemName: "event_category_name",
            itemId: "event_category_id",
        },
        121: {
            itemName: "event_category_name",
            itemId: "event_category_id",
        },
        130: {
            itemName: "event_requirement_name",
            itemId: "event_requirement_id",
        },
        131: {
            itemName: "event_requirement_name",
            itemId: "event_requirement_id",
        },
        143: {
            from_dt: "from_dt",
            start_time: "start_time",
            itemName: "created_on_or_after",
        },
        146: {
            itemName: "profile_code",
        },
        147: {
            itemName: "profile_code",
        },
        148: {
            itemName: "standard_schedule_name",
            itemId: "standard_schedule_id",
        },
        149: {
            itemName: "standard_schedule_name",
            itemId: "standard_schedule_id",
        },
        150: {
            itemName: "cust_atrb_detail",
            itemId: "cust_atrb_value",
        },
        151: {
            itemName: "cust_atrb_detail",
            itemId: "cust_atrb_value",
        },
        152: {
            itemName: "cust_atrb_detail",
            itemId: "cust_atrb_value",
        },
        153: {
            itemName: "cust_atrb_detail",
            itemId: "cust_atrb_value",
        },
        154: {
            itemName: "cust_atrb_detail",
            itemId: "cust_atrb_value",
        },
        155: {
            itemName: "cust_atrb_detail",
            itemId: "cust_atrb_value",
        },
        156: {
            itemName: "cust_atrb_detail",
            itemId: "cust_atrb_value",
        },
        157: {
            itemName: "cust_atrb_detail",
            itemId: "cust_atrb_value",
        },
        158: {
            itemId: "cust_atrb_value",
        },
        163: {
            itemName: "space_name",
            itemId: "space_id",
        },
        165: {
            itemName: "space_name",
            itemId: "space_id",
        },
        162: {
            itemName: "space_search_name",
            itemId: "space_search_id",
        },
        168: {
            itemName: "resource_name",
            itemId: "resource_id",
        },
        167: {
            itemName: "resource_search_name",
            itemId: "resource_search_id",
        },
    },

    4: {
        400: {
            itemName: "space_name",
            searchMethod: "space_name_search_method",
        },
        445: {
            itemName: "keyword",
        },
        405: {
            itemName: "space_search_name",
            itemId: "space_search_id",
        },
        407: {
            itemName: "space_name",
            itemId: "space_id",
        },
        425: {
            itemName: "building_name",
            itemId: "building_id",
        },
        426: {
            itemName: "building_name",
            itemId: "building_id",
        },
        423: {
            itemName: "partition_name",
            itemId: "partition_id",
        },
        424: {
            itemName: "partition_name",
            itemId: "partition_id",
        },
        410: {
            itemName: "feature_name",
            itemId: "feature_id",
        },
        411: {
            itemName: "feature_name",
            itemId: "feature_id",
        },
        420: {
            itemName: "category_name",
            itemId: "category_id",
        },
        421: {
            itemName: "category_name",
            itemId: "category_id",
        },
        430: {
            itemName: "layout_name",
            itemId: "layout_id",
        },
        433: {
            itemName: "layout_name",
            itemId: "layout_id",
        },
        460: {
            itemName: "related_space_name",
            itemId: "related_space_id",
        },
        461: {
            itemName: "related_space_name",
            itemId: "related_space_id",
        },
        450: {
            itemName: "cust_atrb_detail",
            itemId: "cust_atrb_value",
        },
        451: {
            itemName: "cust_atrb_detail",
            itemId: "cust_atrb_value",
        },
        452: {
            itemName: "cust_atrb_detail",
            itemId: "cust_atrb_value",
        },
        453: {
            itemName: "cust_atrb_detail",
            itemId: "cust_atrb_value",
        },
        454: {
            itemName: "cust_atrb_detail",
            itemId: "cust_atrb_value",
        },
        455: {
            itemName: "cust_atrb_detail",
            itemId: "cust_atrb_value",
        },
        456: {
            itemName: "cust_atrb_detail",
            itemId: "cust_atrb_value",
        },
        457: {
            itemName: "cust_atrb_detail",
            itemId: "cust_atrb_value",
        },
        458: {
            itemName: "cust_atrb_detail",
            itemId: "cust_atrb_value",
        },
        470: {
            itemName: "event_search_name",
            itemId: "event_search_id",
        },
        471: {
            itemName: "event_name",
            itemId: "event_id",
        },
        20: {
            itemName: "relationship_type_name",
            itemId: "relationship_type_id",
        },
    },

    2: {
        200: {
            itemName: "organization_name",
            searchMethod: "organization_name_search_method",
        },
        245: {
            itemName: "keyword",
        },
        205: {
            itemName: "organization_search_name",
            itemId: "organization_search_id",
        },
        207: {
            itemName: "organization_name",
            itemId: "organization_id",
        },
        220: {
            itemName: "category_name",
            itemId: "category_id",
        },
        221: {
            itemName: "category_name",
            itemId: "category_id",
        },
        210: {
            itemName: "contact_name",
            itemId: "contact_id",
        },
        230: {
            itemName: "type_name",
            itemId: "type_id",
        },
        231: {
            itemName: "type_name",
            itemId: "type_id",
        },
        250: {
            itemName: "cust_atrb_detail",
            itemId: "cust_atrb_value",
        },
        251: {
            itemName: "cust_atrb_detail",
            itemId: "cust_atrb_value",
        },
        252: {
            itemName: "cust_atrb_detail",
            itemId: "cust_atrb_value",
        },
        253: {
            itemName: "cust_atrb_detail",
            itemId: "cust_atrb_value",
        },
        254: {
            itemName: "cust_atrb_detail",
            itemId: "cust_atrb_value",
        },
        255: {
            itemName: "cust_atrb_detail",
            itemId: "cust_atrb_value",
        },
        256: {
            itemName: "cust_atrb_detail",
            itemId: "cust_atrb_value",
        },
        257: {
            itemName: "cust_atrb_detail",
            itemId: "cust_atrb_value",
        },
        258: {
            itemName: "cust_atrb_detail",
            itemId: "cust_atrb_value",
        },
        280: {
            itemName: "rating_name",
            itemId: "rating_id",
        },
        281: {
            itemName: "rating_name",
            itemId: "rating_id",
        },
        270: {
            itemName: "event_search_name",
            itemId: "event_search_id",
        },
        271: {
            itemName: "event_name",
            itemId: "event_id",
        },
    },

    6: {
        600: {
            itemName: "resource_name",
            searchMethod: "resource_name_search_method",
            step_type_id: "step_type",
        },
        645: {
            itemName: "keyword",
        },
        605: {
            itemName: "resource_search_name",
            itemId: "resource_search_id",
            step_type_id: "step_type",
        },
        607: {
            itemName: "resource_name",
            itemId: "resource_id",
            step_type_id: "step_type",
        },
        620: {
            itemName: "category_name",
            itemId: "category_id",
            step_type_id: "step_type",
        },
        621: {
            itemName: "category_name",
            itemId: "category_id",
            step_type_id: "step_type",
        },
        660: {
            itemName: "resource_name",
            itemId: "resource_id",
            step_type_id: "step_type",
        },
        661: {
            itemName: "resource_name",
            itemId: "resource_id",
            step_type_id: "step_type",
        },
        650: {
            itemName: "cust_atrb_detail",
            itemId: "cust_atrb_value",
            step_type_id: "step_type",
        },
        651: {
            itemName: "cust_atrb_detail",
            itemId: "cust_atrb_value",
            step_type_id: "step_type",
        },
        652: {
            itemName: "cust_atrb_detail",
            itemId: "cust_atrb_value",
            step_type_id: "step_type",
        },
        653: {
            itemName: "cust_atrb_detail",
            itemId: "cust_atrb_value",
            step_type_id: "step_type",
        },
        654: {
            itemName: "cust_atrb_detail",
            itemId: "cust_atrb_value",
            step_type_id: "step_type",
        },
        655: {
            itemName: "cust_atrb_detail",
            itemId: "cust_atrb_value",
            step_type_id: "step_type",
        },
        656: {
            itemName: "cust_atrb_detail",
            itemId: "cust_atrb_value",
            step_type_id: "step_type",
        },
        657: {
            itemName: "cust_atrb_detail",
            itemId: "cust_atrb_value",
            step_type_id: "step_type",
        },
        658: {
            itemName: "cust_atrb_detail",
            itemId: "cust_atrb_value",
            step_type_id: "step_type",
        },
        670: {
            itemName: "event_search_name",
            itemId: "event_search_id",
            step_type_id: "step_type",
        },
        671: {
            itemName: "event_name",
            itemId: "event_id",
            step_type_id: "step_type",
        },
    },

    10: {
        1010: {
            from_dt: "between_start_dt",
            until_dt: "between_end_dt",
        },
        1015: {
            from_dt: "between_start_dt",
            until_dt: "between_end_dt",
        },
        1020: {
            from_dt: "between_start_dt",
            until_dt: "between_end_dt",
        },
        1021: {
            from_dt: "between_start_dt",
            until_dt: "between_end_dt",
        },
        1022: {
            from_dt: "between_start_dt",
            until_dt: "between_end_dt",
        },
    },
};
