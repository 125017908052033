import { Timeout } from "../decorators/timeout.decorator";
import { DataAccess } from "../dataaccess/data.access";
import { S25Util } from "../util/s25-util";
import { Cache, Invalidate } from "../decorators/cache.decorator";
import { Rules } from "../modules/s25-rule-tree/s25.rule.const";
import { Proto } from "../pojo/Proto";
import NumericalBoolean = Proto.NumericalBoolean;

export interface RuleTargetItem {
    itemId: number;
    itemName: string;
    itemTypeId: Rules.TargetId;
    itemValue?: string;
    sort_order?: number; // Only for custom attributes?
    custAtrbType?: Rules.AttributeType; // Only for itemTypeId === CustomAttribute (11)
    value?: string;
    action?: Rules.Action;
}

export interface RuleValue {
    value: string;
    itemName?: string; // Not present if operator is "in"
}

export interface RuleSource {
    itemName: string;
    itemTypeId: Rules.TypeId;
    itemId?: number; // Only with custom attributes?
    custAtrbType?: Rules.AttributeType; // Only for itemTypeId === CustomAttribute (11)
}

export interface RuleItem {
    operator: Rules.Operator;
    type: "comp";
    sourceItem: RuleSource;
    val_obj: RuleValue[];
    children: "";
}

export interface RuleItems {
    operator: Rules.Conjunction;
    type: "bool";
    children: {
        item: (RuleItems | RuleItem)[];
    };
}

export interface Rule {
    rule_name: string;
    rule_cat: string;
    root_rule_id: number;
    active: NumericalBoolean;
    item: RuleItems & {
        targetAction?: Rules.Action; // NOTE: Is set as "target_action" but returned as "targetAction"
        targetItem?: RuleTargetItem[];
        actions: RuleAction[];
    };
}

export type RuleAction = {
    action: Rules.Action;
    targets: RuleActionTarget[];
};

export type RuleActionTarget = {
    itemTypeId: number;
    itemId: number;
    itemName: string;
    itemValue: string;
    custAtrbType?: Rules.AttributeType; // Only for custom attributes (itemTypeId = 11)
};

export interface RuleTableItem {
    dummy_id: number; // Dummy ids determine sort order!
    parent_dummy_id?: number; // Only if it has a parent
    operator: Rules.Conjunction | Rules.Operator;
    rule_type: "bool" | "comp";
    value?: any; // Can be put on RuleValueList instead
    source_item_id?: number; // Only with Custom Attributes
    source_item_type_id?: Rules.TypeId; // CustomAttribute (11) or Object (2, 4, 6, 19, 99)
    // Only for first item!
    rule_cat?: string;
    rule_name?: string | number;
    target_action?: Rules.Action;
    target_item_id?: number; // Can be put in RuleTargetList instead
    target_item_type_id?: Rules.TargetId;
    target_item_value?: string; // Only Alert/Notify user
}

export interface RuleValueListItem {
    dummy_id: number;
    value: any;
}

export interface RuleActionListItem {
    dummy_id: number;
    action: Rules.Action;
    targets: RuleActionListTarget[];
}

export type RuleActionListTarget = {
    target_type_id: number;
    target_id: number;
    value: string;
};

export class RuleTreeService {
    @Timeout
    @Cache({ targetName: "RuleTreeService", immutable: true })
    public static async getRules(category: string = "form", expanded: boolean = false): Promise<Rule[]> {
        let url = `/rule/trees.json?category_id=${category}`;
        if (expanded) url += "&expanded=T";
        const rules = (await DataAccess.get(url))?.rule || [];

        for (let rule of rules) {
            for (let { targets } of rule.item.actions) {
                for (let item of targets || []) {
                    if (S25Util.isDefined(item.itemValue)) item.itemValue = String(item.itemValue); // Service returns numerical strings as numbers
                }
            }
        }

        return rules;
    }

    @Timeout
    @Invalidate({ serviceName: "RuleTreeService", methodName: "getRules" })
    public static putRule(
        id: string | number,
        active: boolean,
        ruleTable: RuleTableItem[],
        valueList: RuleValueListItem[],
        actionList: RuleActionListItem[],
    ) {
        const payload = {
            root: {
                active: +active,
                rule: S25Util.deleteUndefDeep(ruleTable),
                valueList: S25Util.deleteUndefDeep(valueList),
                actions: S25Util.deleteUndefDeep(actionList),
            },
        };
        return DataAccess.put(`/rule/tree.json?itemId=${id}`, payload);
    }

    @Timeout
    @Invalidate({ serviceName: "RuleTreeService", methodName: "getRules" })
    public static delRule(id: string | number): Promise<{}> {
        return DataAccess.delete(`/rule/tree.json?itemId=${id}`);
    }
}
