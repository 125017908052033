//@author: mandy
import { DataAccess } from "../../../dataaccess/data.access";
import { S25Util } from "../../../util/s25-util";
import { SearchService } from "../search.service";
import { RoleService } from "../../role.service";
import { jSith } from "../../../util/jquery-replacement";
import { Cache } from "../../../decorators/cache.decorator";
import { Timeout } from "../../../decorators/timeout.decorator";
import { GroupService } from "../../group.service";
import { EventService } from "../../event.service";
import { Item } from "../../../pojo/Item";
import { BuildingService } from "../../building.service";
import { ReportService } from "../../report.service";
import { Rules } from "../../../modules/s25-rule-tree/s25.rule.const";
import { StandardScheduleService } from "../../standard.schedule.service";
import { Proto } from "../../../pojo/Proto";
import NumericalBoolean = Proto.NumericalBoolean;
import { BpeService } from "../../../modules/bpe/bpe.service";
import { MasterDefinitionTagsService } from "../../master.definitions/master.definition.tags.service";
import { MasterDefTag } from "../../../pojo/MasterDefTag";

export interface CustomAttributeResponseItem {
    itemId: number;
    itemName: string;
    itemTypeId: Rules.AttributeType;
    sort_order: number;
    multi_val?: NumericalBoolean;
}

export class SearchCriteriaService {
    public static setItemTypeId(obj: any, itemTypeId: number) {
        let items = S25Util.array.isArray(obj) ? obj : (obj && obj.searchCriteria && obj.searchCriteria.item) || [];
        jSith.forEach(items, function (key: any, item: any) {
            item.itemTypeId = itemTypeId;
        });
        return obj;
    }

    public static errorHandler(err: any): any {
        return [];
    }

    public static composeUrl(
        path: any,
        caller: any,
        useSecurity?: any,
        eventTypeId?: number,
        activeOnly?: any,
        customFilterValue?: any,
    ) {
        let searchQuery = "";

        if (S25Util.toBool(activeOnly)) {
            //only return active items
            searchQuery = S25Util.addQueryParam("active=T", searchQuery);
        }

        if (S25Util.toBool(useSecurity)) {
            //only return items for which the user has security (eg, via config ev_types and rose security allowed_event_types pref)
            searchQuery = S25Util.addQueryParam("all_types=F", searchQuery);
        }

        if (eventTypeId) {
            //further filter by event type (evt_* tables)
            searchQuery = S25Util.addQueryParam("event_type_id=" + eventTypeId, searchQuery);
        }

        if (customFilterValue) {
            searchQuery = S25Util.addQueryParam(customFilterValue, searchQuery);
        }

        path = "/search-criteria" + path;
        path = path + (searchQuery ? (path.indexOf("?") > -1 ? searchQuery : "?" + searchQuery.substring(1)) : "");

        return DataAccess.injectCaller(path, caller);
    }

    public static handlePagination(
        cacheId: number,
        pageNum: number,
        pageSize: number,
        filter: any,
        filterFavorites: any,
        domainFilter: any,
        customFilterValue: any,
        url: any,
    ) {
        let queryString = "";
        let isFilter = !S25Util.isUndefined(filter) && filter.trim() !== "";
        cacheId = cacheId || 0;
        pageSize = pageSize || 100;
        pageNum = pageNum || 0;

        if (cacheId >= 0) {
            //negative cacheId indicates to force no pagination
            queryString += "&obj_cache_accl=" + cacheId;
            queryString += "&page=" + pageNum;
            queryString += "&page_size=" + pageSize;
        } else if (pageSize) {
            queryString += "&page_size=" + pageSize;
        }

        queryString += filterFavorites === true ? "&favorite=T" : "";
        queryString +=
            domainFilter && domainFilter.chosen && domainFilter.chosen.itemId > -999
                ? domainFilter.prefix + domainFilter.chosen.itemId
                : "";
        queryString += isFilter ? "&name=" + filter : "";
        queryString += customFilterValue || "";
        return url + "?" + queryString.substring(1);
    }

    @Timeout
    @Cache({ immutable: true, targetName: "SearchCriteriaService" })
    public static getCustomAttributeItems(
        typeId: Item.Id,
        useSecurity?: any,
        eventTypeId?: any,
    ): Promise<CustomAttributeResponseItem[]> {
        //note: itemTypeId is cust atrb type (B, N, F, X, etc), itemName is cust atrb name, itemId is cust atrb id
        let url = "/customattributes.json?itemTypeId=" + typeId;

        return DataAccess.get(
            SearchCriteriaService.composeUrl(
                url,
                "SearchCriteriaService.getCustomAttributes",
                useSecurity,
                eventTypeId,
                null,
                null,
            ),
        ).then(function (data) {
            const items = data?.searchCriteria?.item || [];
            for (let item of items) item.isCustomAttribute = true;
            return items;
        }, SearchCriteriaService.errorHandler);
    }

    @Timeout
    public static optSearchesToItemList(searches: any) {
        let ret: any = [],
            map: any = {};
        searches = searches && searches.opt;
        jSith.forEach(searches, function (key: any, s: any) {
            if (s && S25Util.isDefined(s.itemId) && !map[s.itemId]) {
                map[s.itemId] = true;
                ret.push({
                    itemId: s.itemId,
                    itemName: s.txt,
                    favorite: s.favorite,
                    isOwner: s.isOwner,
                    val: s.val,
                });
            }
        });
        return ret;
    }

    //extract items from search criteria return data
    public static extractItems(data: any) {
        return (data && data.searchCriteria && data.searchCriteria.item) || (S25Util.array.isArray(data) ? data : []);
    }

    //security groups
    @Timeout
    @Cache({ immutable: true, targetName: "SearchCriteriaService" })
    public static getSecurityGroups() {
        return GroupService.getGroupItems().then(function (items) {
            return SearchCriteriaService.setItemTypeId(items, 99);
        }, SearchCriteriaService.errorHandler);
    }

    // EventType Reports
    @Timeout
    @Cache({ immutable: true, targetName: "SearchCriteriaService" })
    public static getEventTypeReports() {
        return EventService.getEventTypeReports().then(function (items) {
            items = items.filter((i: any) => [1, 10, 12, 13].indexOf(i.object_type) > -1); //  filter object_type === 11 (pricing set) ANG-4741 ANG-4852
            return ReportService.normalizeReport(items, true); //mapping itemId itemName and DM report name
        }, SearchCriteriaService.errorHandler);
    }

    //events
    @Timeout
    @Cache({ immutable: true, targetName: "SearchCriteriaService" })
    public static getEventRequirements(useSecurity: any, eventTypeId: number, customFilterValue: string) {
        useSecurity = useSecurity || S25Util.isDefined(eventTypeId); //set to true if event type id sent in so that downsteam code handles it (all types set to F)
        return DataAccess.get(
            SearchCriteriaService.composeUrl(
                "/event/requirements.json",
                "SearchCriteriaService.getEventRequirements",
                useSecurity,
                eventTypeId,
                null,
                customFilterValue,
            ),
        ).then(function (data) {
            //ANG-4149 only show types 6/7 for requirements
            let resp = (data && data.searchCriteria && data.searchCriteria.item) || [];
            //When useSecurity is false, resp body won't include an itemTypeId so do not filter
            return useSecurity || S25Util.contains(customFilterValue, "all_types=F")
                ? resp.filter((item: any) => {
                      return [6, 7].indexOf(S25Util.parseInt(item.itemTypeId)) > -1;
                  })
                : resp;
        }, SearchCriteriaService.errorHandler);
    }

    @Timeout
    public static getEventSearches() {
        return SearchService.getStrictSearches(1).then(function (searches) {
            return SearchCriteriaService.optSearchesToItemList(searches);
        });
    }

    @Timeout
    @Cache({ immutable: true, targetName: "SearchCriteriaService", expireTimeMs: 60000 })
    public static getEvents(
        cacheId: number,
        pageNum: number,
        pageSize: number,
        filter: any,
        filterFavorites: any,
        domainFilter: any,
        customFilterValue: any,
    ) {
        return DataAccess.get(
            SearchCriteriaService.composeUrl(
                SearchCriteriaService.handlePagination(
                    cacheId,
                    pageNum,
                    pageSize,
                    filter,
                    filterFavorites,
                    domainFilter,
                    customFilterValue,
                    "/event/events.json",
                ),
                "SearchCriteriaService.getEvents",
                null,
                null,
                null,
                null,
            ),
        ).then(function (data) {
            return SearchCriteriaService.setItemTypeId(data || [], 1);
        }, SearchCriteriaService.errorHandler);
    }

    @Timeout
    @Cache({ immutable: true, targetName: "SearchCriteriaService" })
    public static getEventCategories(useSecurity: number, eventTypeId: number, omitReadOnly: boolean) {
        return DataAccess.get(
            SearchCriteriaService.composeUrl(
                "/event/categories.json",
                "SearchCriteriaService.getEventCategories",
                useSecurity,
                eventTypeId,
                null,
                null,
            ),
        ).then(function (data) {
            const items = (data && data.searchCriteria && data.searchCriteria.item) || [];
            return omitReadOnly
                ? items
                : items.filter((item: { tags: MasterDefTag.MdTag[] }) =>
                      MasterDefinitionTagsService.isItemReadOnly(item?.tags),
                  );
        }, SearchCriteriaService.errorHandler);
    }

    @Timeout
    @Cache({ immutable: true, targetName: "SearchCriteriaService" })
    public static getEventTypes(useSecurity: any, activeOnly: any, formConfigOnly?: any) {
        let customFilterValue = S25Util.toBool(formConfigOnly) ? "form_config=T" : null;
        return DataAccess.get(
            SearchCriteriaService.composeUrl(
                "/event/types.json",
                "SearchCriteriaService.getEventTypes",
                useSecurity,
                null,
                activeOnly,
                customFilterValue,
            ),
        ).then(function (data) {
            data = (data && data.searchCriteria && data.searchCriteria.item) || [];
            data = SearchCriteriaService.setItemTypeId(data, 7);
            return data.filter(function (item: any) {
                return item.itemName && (item.itemName + "").toLowerCase() !== "(unspecified)";
            });
        }, SearchCriteriaService.errorHandler);
    }

    @Timeout
    @Cache({ immutable: true, targetName: "SearchCriteriaService" })
    public static getEventCabinets() {
        return DataAccess.get(
            SearchCriteriaService.composeUrl(
                "/event/cabinets.json",
                "SearchCriteriaService.getEventCabinets",
                null,
                null,
                null,
                null,
            ),
        ).then(function (data) {
            return (data && data.searchCriteria && data.searchCriteria.item) || [];
        }, SearchCriteriaService.errorHandler);
    }

    @Timeout
    @Cache({ immutable: true, targetName: "SearchCriteriaService" })
    public static getEventStates() {
        return DataAccess.get(
            SearchCriteriaService.composeUrl(
                "/event/states.json",
                "SearchCriteriaService.getEventStates",
                null,
                null,
                null,
                null,
            ),
        ).then(function (data) {
            return (data && data.searchCriteria && data.searchCriteria.item) || [];
        }, SearchCriteriaService.errorHandler);
    }

    @Timeout
    public static getAssignedEventStates() {
        return SearchCriteriaService.getEventStates().then(function (items: any) {
            return (
                items &&
                items.length &&
                items.filter(function (item: any) {
                    return [1, 2, 3].indexOf(item.itemId) > -1;
                })
            );
        });
    }

    @Timeout
    @Cache({ immutable: true, targetName: "SearchCriteriaService" })
    public static getEventRoles(useSecurity: any, typedRolesOnly: boolean) {
        if (typedRolesOnly) {
            return RoleService.getEventTypeRoles(null).then(function (roles: any) {
                return roles.map(function (role: any) {
                    return {
                        itemId: role.role_id,
                        itemName: role.role_name,
                    };
                });
            });
        } else {
            return DataAccess.get(
                SearchCriteriaService.composeUrl(
                    "/event/roles.json",
                    "SearchCriteriaService.getEventRoles",
                    useSecurity,
                    null,
                    null,
                    null,
                ),
            ).then(function (data) {
                return (data && data.searchCriteria && data.searchCriteria.item) || [];
            }, SearchCriteriaService.errorHandler);
        }
    }

    @Timeout
    public static getEventCustomAttributes() {
        return SearchCriteriaService.getCustomAttributeItems(1, null, null);
    }

    @Timeout
    public static getFolders(
        cacheId: any,
        pageNum: number,
        pageSize: number,
        filter: any,
        filterFavorites: any,
        domainFilter: any,
        customFilterValue: any,
    ) {
        customFilterValue = customFilterValue || "";
        customFilterValue += "&node_type=F";
        return DataAccess.get(
            SearchCriteriaService.composeUrl(
                SearchCriteriaService.handlePagination(
                    cacheId,
                    pageNum,
                    pageSize,
                    filter,
                    filterFavorites,
                    domainFilter,
                    customFilterValue,
                    "/event/events.json",
                ),
                "SearchCriteriaService.getFolders",
                null,
                null,
                null,
                null,
            ),
        ).then(function (data) {
            return SearchCriteriaService.setItemTypeId(data || [], 1);
        }, SearchCriteriaService.errorHandler);
    }

    //locations
    @Timeout
    @Cache({ immutable: true, targetName: "SearchCriteriaService" })
    public static getSpaceCategories(omitReadOnly?: boolean) {
        return DataAccess.get(
            SearchCriteriaService.composeUrl(
                "/space/categories.json",
                "SearchCriteriaService.getSpaceCategories",
                true,
                null,
                null,
                null,
            ),
        ).then(function (data) {
            const items = (data && data.searchCriteria && data.searchCriteria.item) || [];
            return omitReadOnly
                ? items
                : items.filter((item: { tags: MasterDefTag.MdTag[] }) =>
                      MasterDefinitionTagsService.isItemReadOnly(item?.tags),
                  );
        }, SearchCriteriaService.errorHandler);
    }

    @Timeout
    @Cache({ immutable: true, targetName: "SearchCriteriaService" })
    public static getSpaceFeatures(
        eventTypeId?: number,
        activeOnly?: any,
        customFilterValue?: any,
        omitReadOnly?: boolean,
    ) {
        customFilterValue = customFilterValue || null;
        return DataAccess.get(
            SearchCriteriaService.composeUrl(
                "/space/features.json",
                "SearchCriteriaService.getSpaceFeatures",
                true,
                null,
                null,
                customFilterValue,
            ),
        ).then(function (data) {
            const items = data?.searchCriteria?.item || [];
            return omitReadOnly
                ? items
                : items.filter((item: { tags: MasterDefTag.MdTag[] }) =>
                      MasterDefinitionTagsService.isItemReadOnly(item?.tags),
                  );
        }, SearchCriteriaService.errorHandler);
    }

    @Timeout
    @Cache({ immutable: true, targetName: "SearchCriteriaService" })
    public static getSpaceLayouts(eventTypeId?: number, activeOnly?: any, customFilterValue?: any) {
        return DataAccess.get(
            SearchCriteriaService.composeUrl(
                "/space/layouts.json",
                "SearchCriteriaService.getSpaceLayouts",
                true,
                null,
                null,
                customFilterValue,
            ),
        ).then(function (data) {
            return (data && data.searchCriteria && data.searchCriteria.item) || [];
        }, SearchCriteriaService.errorHandler);
    }

    @Timeout
    public static getLocationSearches() {
        return SearchService.getStrictSearches(4).then(function (searches) {
            return SearchCriteriaService.optSearchesToItemList(searches);
        });
    }

    @Timeout
    @Cache({ immutable: true, targetName: "SearchCriteriaService", expireTimeMs: 60000 })
    public static getLocations(
        cacheId: number,
        pageNum: number,
        pageSize: number,
        filter: any,
        filterFavorites: any,
        domainFilter: any,
        customFilterValue: any,
    ) {
        return DataAccess.get(
            SearchCriteriaService.composeUrl(
                SearchCriteriaService.handlePagination(
                    cacheId,
                    pageNum,
                    pageSize,
                    filter,
                    filterFavorites,
                    domainFilter,
                    customFilterValue,
                    "/space/locations.json",
                ),
                "SearchCriteriaService.getLocations",
                null,
                null,
                null,
                null,
            ),
        ).then(function (data) {
            return SearchCriteriaService.setItemTypeId(data || [], 4);
        }, SearchCriteriaService.errorHandler);
    }

    @Timeout
    @Cache({ immutable: true, targetName: "SearchCriteriaService" })
    public static getLocationPartitions() {
        return DataAccess.get(
            SearchCriteriaService.composeUrl(
                "/space/partitions.json",
                "SearchCriteriaService.getLocationPartitions",
                null,
                null,
                null,
                null,
            ),
        ).then(function (data) {
            return (data && data.searchCriteria && data.searchCriteria.item) || [];
        }, SearchCriteriaService.errorHandler);
    }

    @Timeout
    @Cache({ immutable: true, targetName: "SearchCriteriaService" })
    public static getLocationRelatedTypes() {
        return jSith.when([
            { itemName: "Subdivision Of", itemId: 1 },
            { itemName: "Close To", itemId: 2 },
            { itemName: "Blocked By", itemId: 3 },
            { itemName: "Also Assign", itemId: 4 },
            { itemName: "Divides Into", itemId: 5 },
        ]);
    }

    @Timeout
    public static getLocationCustomAttributes() {
        return SearchCriteriaService.getCustomAttributeItems(4, null, null);
    }

    @Timeout
    //organizations
    public static getOrganizationSearches() {
        return SearchService.getStrictSearches(2).then(function (searches) {
            return SearchCriteriaService.optSearchesToItemList(searches);
        });
    }

    @Timeout
    @Cache({ immutable: true, targetName: "SearchCriteriaService" })
    public static getOrganizationRatings() {
        return DataAccess.get(
            SearchCriteriaService.composeUrl(
                "/organization/ratings.json",
                "SearchCriteriaService.getOrganizationRatings",
                null,
                null,
                null,
                null,
            ),
        ).then(function (data) {
            return (data && data.searchCriteria && data.searchCriteria.item) || [];
        }, SearchCriteriaService.errorHandler);
    }

    @Timeout
    @Cache({ immutable: true, targetName: "SearchCriteriaService" })
    public static getOrganizationRoles(useSecurity?: boolean, activeOnly?: boolean) {
        //use security is there only to follow the other patterns
        return DataAccess.get(
            SearchCriteriaService.composeUrl(
                "/organization/roles.json",
                "SearchCriteriaService.getOrganizationRoles",
                null,
                null,
                activeOnly,
                null,
            ),
        ).then(function (data) {
            let resp = (data && data.searchCriteria && data.searchCriteria.item) || [];
            return resp.map((role: any) => {
                role.isActive = !role.isInactive; //Make sure .isActive from time when this service relied on getRoles.
                return role;
            });
        }, SearchCriteriaService.errorHandler);
    }

    @Timeout
    @Cache({ immutable: true, targetName: "SearchCriteriaService" })
    public static getOrganizationCategories(omitReadOnly?: boolean) {
        return DataAccess.get(
            SearchCriteriaService.composeUrl(
                "/organization/categories.json",
                "SearchCriteriaService.getOrganizationCategories",
                true,
                null,
                null,
                null,
            ),
        ).then(function (data) {
            return (data && data.searchCriteria && data.searchCriteria.item) || [];
        }, SearchCriteriaService.errorHandler);
    }

    @Timeout
    @Cache({ immutable: true, targetName: "SearchCriteriaService" })
    public static getOrganizationTypes() {
        return DataAccess.get(
            SearchCriteriaService.composeUrl(
                "/organization/types.json",
                "SearchCriteriaService.getOrganizationTypes",
                true,
                null,
                null,
                null,
            ),
        ).then(function (data) {
            return (data && data.searchCriteria && data.searchCriteria.item) || [];
        }, SearchCriteriaService.errorHandler);
    }

    @Timeout
    @Cache({ immutable: true, targetName: "SearchCriteriaService", expireTimeMs: 60000 })
    public static getEventOrganizations(
        cacheId: number,
        pageNum: number,
        pageSize: number,
        filter: any,
        filterFavorites: any,
        domainFilter: any,
        customFilterValue: any,
    ) {
        return DataAccess.get(
            SearchCriteriaService.composeUrl(
                SearchCriteriaService.handlePagination(
                    cacheId,
                    pageNum,
                    pageSize,
                    filter,
                    filterFavorites,
                    domainFilter,
                    customFilterValue,
                    "/event/organizations.json",
                ),
                "SearchCriteriaService.getEventOrganizations",
                null,
                null,
                null,
                null,
            ),
        ).then(function (data) {
            return SearchCriteriaService.setItemTypeId(data || [], 2);
        }, SearchCriteriaService.errorHandler);
    }

    @Timeout
    public static getOrganizationCustomAttributes() {
        return SearchCriteriaService.getCustomAttributeItems(2, true, null);
    }

    //resources
    @Timeout
    @Cache({ immutable: true, targetName: "SearchCriteriaService" })
    public static getResourceCategories(omitReadOnly?: boolean) {
        return DataAccess.get(
            SearchCriteriaService.composeUrl(
                "/resource/categories.json",
                "SearchCriteriaService.getResourceCategories",
                true,
                null,
                null,
                null,
            ),
        ).then(function (data) {
            return (data && data.searchCriteria && data.searchCriteria.item) || [];
        }, SearchCriteriaService.errorHandler);
    }

    @Timeout
    public static getResourceSearches() {
        return SearchService.getStrictSearches(6).then(function (searches) {
            return SearchCriteriaService.optSearchesToItemList(searches);
        });
    }

    @Timeout
    @Cache({ immutable: true, targetName: "SearchCriteriaService", expireTimeMs: 60000 })
    public static getResources(
        cacheId: number,
        pageNum: number,
        pageSize: number,
        filter: any,
        filterFavorites: any,
        domainFilter: any,
        customFilterValue: any,
        isIncludeQuantity?: any,
    ) {
        var url =
            SearchCriteriaService.composeUrl(
                SearchCriteriaService.handlePagination(
                    cacheId,
                    pageNum,
                    pageSize,
                    filter,
                    filterFavorites,
                    domainFilter,
                    customFilterValue,
                    "/resource/resources.json",
                ),
                "SearchCriteriaService.getResources",
                null,
                null,
                null,
                null,
            ) + (isIncludeQuantity ? DataAccess.composeParam("include", "quantity") : "");
        return DataAccess.get(url).then(function (data) {
            return SearchCriteriaService.setItemTypeId(data || [], 6);
        }, SearchCriteriaService.errorHandler);
    }

    @Timeout
    @Cache({ immutable: true, targetName: "SearchCriteriaService", expireTimeMs: 60000 })
    public static getResourcesQuantity(
        cacheId: number,
        pageNum: number,
        pageSize: number,
        filter: any,
        filterFavorites: any,
        domainFilter: any,
        customFilterValue: any,
    ) {
        return SearchCriteriaService.getResources(
            cacheId,
            pageNum,
            pageSize,
            filter,
            filterFavorites,
            domainFilter,
            customFilterValue,
            true,
        ); //extra param for include quantity
    }

    @Timeout
    public static getResourceCustomAttributes() {
        return SearchCriteriaService.getCustomAttributeItems(6, true, null);
    }

    //contacts
    @Timeout
    @Cache({ immutable: true, targetName: "SearchCriteriaService", expireTimeMs: 60000 })
    public static getContacts(
        cacheId: number,
        pageNum: number,
        pageSize: number,
        filter: any,
        filterFavorites: any,
        domainFilter: any,
        customFilterValue: any,
    ) {
        return DataAccess.get(
            SearchCriteriaService.composeUrl(
                SearchCriteriaService.handlePagination(
                    cacheId,
                    pageNum,
                    pageSize,
                    filter,
                    filterFavorites,
                    domainFilter,
                    customFilterValue,
                    "/contact/contacts.json",
                ),
                "SearchCriteriaService.getContacts",
                null,
                null,
                null,
                null,
            ),
        ).then(function (data) {
            return SearchCriteriaService.setItemTypeId(data || [], 3);
        }, SearchCriteriaService.errorHandler);
    }

    //misc
    @Timeout
    public static getDaysOfWeek() {
        return jSith.when([
            { itemId: 1, itemName: "MO WE FR" },
            { itemId: 1, itemName: "MO WE" },
            { itemId: 1, itemName: "TU TH" },
            { itemId: 1, itemName: "WE FR" },
            { itemId: 1, itemName: "FR" },
        ]);
    }

    /**
     * cached by ServiceCache; used by s25-search-criteria to keep multiselect bean details
     *
     * @param id
     * @param data
     */

    @Timeout
    @Cache({ targetName: "SearchCriteriaService" })
    public static getMultiselectBean(componentId: number, beanName: any) {
        // note: return an empty object here which will be CACHED and used to initialize s25-multiselect module
        // then any updates to this empty modelBean will be kept in the CACHE
        return {};
    }
    /**
     * cached as 'getMultiselectBean' to refresh the cache
     * @param componentId
     * @param beanName
     * @returns {*}
     */

    @Timeout
    @Cache({ targetName: "SearchCriteriaService", cachedMethod: "getMultiselectBean" })
    public static refreshMultiselectBean(componentId: number, beanName: any) {
        return SearchCriteriaService.getMultiselectBean(componentId, beanName);
    }

    @Timeout
    public static getObjectCategories(
        itemTypeId: any,
        useSecurity?: number,
        eventTypeId?: number,
        omitReadOnly?: boolean,
    ) {
        itemTypeId = parseInt(itemTypeId);
        switch (itemTypeId) {
            case 4:
                return SearchCriteriaService.getSpaceCategories(omitReadOnly);
            case 6:
                return SearchCriteriaService.getResourceCategories(omitReadOnly);
            case 2:
                return SearchCriteriaService.getOrganizationCategories(omitReadOnly);
            case 1:
                return SearchCriteriaService.getEventCategories(useSecurity, eventTypeId, omitReadOnly);
        }
    }

    @Timeout
    public static getManualTemplates(useSecurity: boolean = false, omitReadOnly?: boolean) {
        return BpeService.getManualTemplates(!useSecurity, omitReadOnly);
    }

    @Timeout
    @Cache({ immutable: true, targetName: "SearchCriteriaService" })
    public static getBuildings() {
        return BuildingService.getBuildings.apply(this, [].slice.call(arguments)).then((resp: any) => {
            if (resp && resp.items) {
                return resp.items.map((b: any) => {
                    return {
                        itemId: b.id,
                        itemName: b.buildingName,
                    };
                });
            } else {
                return [];
            }
        });
    }

    @Timeout
    @Cache({ immutable: true, targetName: "SearchCriteriaService" })
    public static getStandardSchedules() {
        return StandardScheduleService.getStandardScheduleList(4).then((data) => {
            return S25Util.array.forceArray(data).map((item) => {
                return { itemId: item.id, itemName: item.name };
            });
        });
    }
}
